import { batchActions } from 'redux-batched-actions';
import Axios from 'axios';

import { fetchMasterDataService, fetchMasterDataServicePost } from 'library/api/master-data.service';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { lateReturnsListRequestJson } from 'library/api/endpoint-request-json';
import { saveAs } from 'file-saver';
import { logNetworkError } from 'library/utilities/logError';
import { fetchFromStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';

import {
  FETCH_INITIAL_DATA_FOR_ADMINISTRATION_STATISTICS,
  FETCH_LOAN_REASONS_STATISTIC,
  FETCH_DEMOS_STATISTIC,
  FETCH_SATISFACTION_INDEX_STATISTIC,
  FETCH_DEMOS_CHART_DATA,
  FETCH_LATE_RETURNS_TRANSACTIONS,
  CLEAR_LATE_RETURNS_TRANSACTIONS,
  FETCH_KIT_STATUS_CHART_STATISTICS,
  CLEAR_KIT_STATUS_CHART_STATISTICS,
  CLEAR_STATISTIC,
  FETCH_KIT_DATA_STATISTICS,
  CLEAR_KIT_DATA_STATISTICS,
  CHANGE_KIT_STATUS_CHART_LOADER,
  CHANGE_KIT_DATA_LOADER,
  FETCH_UTILIZED_KITS,
  FETCH_WORKING_CAPITAL_IN_STOCK,
  CLEAR_WORKING_CAPITAL_IN_STOCK,
  NEW_REGISTRATIONS,
  DMA_REPORTING_DATA,
  SIX_MONTHS_TRAFFIC_DATA,
} from './Statistics.constants';

export const getInitialData = () => async dispatch => {
  try {
    dispatch(changeLoader(true));

    const { data: businessUnitsForkits } = await fetchMasterDataService(URLS.userBusinessunitsForKits);
    const { data: countries } = await fetchMasterDataService(URLS.userCountries);

    batchActions([
      dispatch({
        type: FETCH_INITIAL_DATA_FOR_ADMINISTRATION_STATISTICS,
        payload: {
          countries: countries || [],
          businessUnitsForkits: businessUnitsForkits || [],
        },
      }),
      dispatch(changeLoader(false)),
    ]);
  } catch (err) {
    batchActions([
      dispatch(changeLoader(false)),
      dispatch({
        type: FETCH_INITIAL_DATA_FOR_ADMINISTRATION_STATISTICS,
        payload: {
          businessUnits: [],
          countries: [],
        },
      }),
    ]);
  }
};

export const getStocksByBussinessUnitandCountryIds = (countryIds, businessUnitIds, type) => async dispatch => {
  try {
    const dataToSend = {
      countryIds: countryIds ? countryIds : [],
      businessUnitIds: businessUnitIds ? businessUnitIds : []
    }
    const { data: stocks } = await fetchMasterDataServicePost(URLS.stocksByCountriesAndBuIds, dataToSend);
    dispatch({
      type,
      payload: stocks || [],
    });
  } catch (err) {
    dispatch({
      type,
      payload: [],
    });
  }
};

export const getSystemClassesByBU = (businessUnitIds, type) => async dispatch => {
  try {
    const dataToSend = {
      businessUnitIds: businessUnitIds ? businessUnitIds : [],
    }
    const { data } = await fetchMasterDataServicePost(URLS.systemClassIdsByBu, dataToSend);
    dispatch({
      type,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type,
      payload: {},
    });
  }
};

export const getLoanReasonStatistic = dataToSend => async dispatch => {
  try {
    const { data } = await fetchMasterDataServicePost(URLS.statisticLoanReason, dataToSend);

    dispatch({
      type: FETCH_LOAN_REASONS_STATISTIC,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: FETCH_LOAN_REASONS_STATISTIC,
      payload: [],
    });
  }
};

export const clearStatistics = () => dispatch => {
  dispatch({ type: CLEAR_STATISTIC });
};

export const getDemosStatistic = dataToSend => async dispatch => {
  try {
    dispatch(changeLoader(true));
    const { data } = await fetchMasterDataServicePost(URLS.statisticDemoPerformed, dataToSend);

    dispatch({
      type: FETCH_DEMOS_STATISTIC,
      payload: data,
    });
    dispatch(changeLoader(false));
  } catch (err) {
    dispatch({
      type: FETCH_DEMOS_STATISTIC,
      payload: {},
    });
    dispatch(changeLoader(false));
  }
};

export const getSatisfactionIndexStatistics = dataToSend => async dispatch => {
  try {
    dispatch(changeLoader(true));
    const { data } = await fetchMasterDataServicePost(URLS.statisticSatisfactionIndex, dataToSend);

    dispatch({
      type: FETCH_SATISFACTION_INDEX_STATISTIC,
      payload: data,
    });
    dispatch(changeLoader(false));
  } catch (err) {
    dispatch({
      type: FETCH_SATISFACTION_INDEX_STATISTIC,
      payload: {},
    });
    dispatch(changeLoader(false));
  }
};

export const getDemosChartData = dataToSend => async dispatch => {
  try {
    dispatch(changeLoader(true));
    const { data } = await fetchMasterDataServicePost(URLS.statisticDemosPerformedChart, dataToSend);

    dispatch({
      type: FETCH_DEMOS_CHART_DATA,
      payload: data,
    });
    dispatch(changeLoader(false));
  } catch (err) {
    dispatch({
      type: FETCH_DEMOS_CHART_DATA,
      payload: [],
    });
    dispatch(changeLoader(false));
  }
};

export const getLateReturnsTransactions = dataToSend => async dispatch => {
  try {
    dispatch(changeLoader(true));
    const { data } = await fetchMasterDataServicePost(URLS.statisticDelayTransactions, {
      ...dataToSend,
      graph: lateReturnsListRequestJson,
    });

    dispatch({
      type: FETCH_LATE_RETURNS_TRANSACTIONS,
      payload: data,
    });
    dispatch(changeLoader(false));
  } catch (err) {
    dispatch({
      type: FETCH_LATE_RETURNS_TRANSACTIONS,
      payload: {},
    });
    dispatch(changeLoader(false));
  }
};

export const clearLateReturnsTransactions = () => dispatch => {
  dispatch({ type: CLEAR_LATE_RETURNS_TRANSACTIONS });
};

export const fetchKitStatusChartStatistics = dataToSend => async (dispatch, getState) => {
  try {
    batchActions([
      dispatch(changeLoader(true)),
      dispatch(changeKitStatusChartLoader(true)),
    ]);

    const { data } = await fetchMasterDataServicePost(URLS.statisticKitStatusChart, dataToSend);

    batchActions([
      dispatch({
        type: FETCH_KIT_STATUS_CHART_STATISTICS,
        payload: data,
      }),
      dispatch(changeKitStatusChartLoader(false)),
    ]);

    const { isKitDataLoading } = getState().statisticsReducer;
    !isKitDataLoading && dispatch(changeLoader(false));
  } catch (err) {
    const { isKitDataLoading } = getState().statisticsReducer;
    batchActions([
      dispatch({
        type: FETCH_KIT_STATUS_CHART_STATISTICS,
        payload: {},
      }),
      dispatch(changeKitStatusChartLoader(false)),
    ]);
    !isKitDataLoading && dispatch(changeLoader(false));
  }
};

export const clearKitStatusChartStatistics = () => async dispatch => {
  dispatch({ type: CLEAR_KIT_STATUS_CHART_STATISTICS });
};

export const fetchKitDataStatistics = dataToSend => async (dispatch, getState) => {
  try {
    batchActions([
      dispatch(changeLoader(true)),
      dispatch(changeKitDataLoader(true)),
    ]);

    const { data } = await fetchMasterDataServicePost(URLS.statisticKitDataDashboard, dataToSend);

    batchActions([
      dispatch({
        type: FETCH_KIT_DATA_STATISTICS,
        payload: data,
      }),
      dispatch(changeKitDataLoader(false)),
    ]);

    const { isKitStatusChartLoading } = getState().statisticsReducer;
    !isKitStatusChartLoading && dispatch(changeLoader(false));
  } catch (err) {
    const { isKitStatusChartLoading } = getState().statisticsReducer;
    batchActions([
      dispatch({
        type: FETCH_KIT_DATA_STATISTICS,
        payload: {},
      }),
      dispatch(changeKitDataLoader(false)),
    ]);
    !isKitStatusChartLoading && dispatch(changeLoader(false));
  }
};

export const clearKitDataStatistics = () => dispatch => {
  dispatch({ type: CLEAR_KIT_DATA_STATISTICS });
};

export const changeKitStatusChartLoader = payload => dispatch => {
  dispatch({
    type: CHANGE_KIT_STATUS_CHART_LOADER,
    payload,
  });
};

export const changeKitDataLoader = payload => dispatch => {
  dispatch({
    type: CHANGE_KIT_DATA_LOADER,
    payload,
  });
};

export const fetchUtilizedKits = dataToSend => async dispatch => {
  try {
    batchActions([
      dispatch(changeLoader(true)),
      dispatch(changeKitDataLoader(true)),
    ]);
    const { data } = await fetchMasterDataServicePost(URLS.utilizedKits, dataToSend);
    dispatch({
      type: FETCH_UTILIZED_KITS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: FETCH_UTILIZED_KITS,
      payload: {},
    });
  } finally{
    batchActions([
      dispatch(changeLoader(false)),
      dispatch(changeKitDataLoader(false)),
    ]);
  }
};
export const fetchWorkingCapitalInStock = dataToSend => async dispatch => {
  try {
    const { data } = await fetchMasterDataServicePost(URLS.capitalOverview, dataToSend);

    dispatch({
      type: FETCH_WORKING_CAPITAL_IN_STOCK,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: FETCH_WORKING_CAPITAL_IN_STOCK,
      payload: {},
    });
  }
};

export const clearWorkingCapitalInStock = () => dispatch => {
  dispatch({
    type: CLEAR_WORKING_CAPITAL_IN_STOCK,
  });
};

export const getDataOnLanguageChange = () => async dispatch => {
  try {
    dispatch(changeLoader(true));
    const { data: countries } = await fetchMasterDataService(URLS.userCountries);

    batchActions([
      dispatch({
        type: FETCH_INITIAL_DATA_FOR_ADMINISTRATION_STATISTICS,
        payload: {
          countries: countries || [],
        },
      }),
      dispatch(changeLoader(false)),
    ]);
  } catch (err) {
    dispatch(changeLoader(false));
  }
};


export const exportLateReturnsTransactionList = (filters,data,totalElements, language) => async (dispatch) => {
  if (filters.businessGroup) {
    const sortField = Object.keys(data.sort).find(item => data.sort[item]) || '';
    const dataToSend = {
      businessUnitIds: filters.businessGroup,
      countryIds: filters.country || [],
      stockIds: filters.stock || [],
      loanType: filters.loanTypesFullValue ? filters.loanTypesFullValue.valueToSend : '',
      sortField,
      sortDirection: data.sort[sortField] || '',
      page: data.page,
      size: totalElements,
    };
    const token = fetchFromStorage(identifiers.token);
    return Axios(process.env.REACT_APP_BASE_URL + URLS.exportLateReturnTransactionsList, {
      method: 'POST',
      responseType: 'blob',
      headers: {
        token: `${token}`,
        'Content-Type': 'application/json',
        'Accept-Language': `${language.language}`,
      },
      data: dataToSend,
    })
      .then(response => {
        try {
          const newFile = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          const dispositionHeader = response.headers['content-disposition'] && response.headers['content-disposition'].split('filename=');
          const filename = dispositionHeader[1] || 'Late Return Transaction data';
          saveAs(newFile, filename);
          return { success: true };
        } catch (e) {
          return { success: false };
        }
      })
      .catch(err => {
        logNetworkError(err);
        return { success: false };
      });
  }
};


export const fetchDmaReportingData = (dataToSend) => async dispatch =>{
  try {
    dispatch(changeLoader(true));
    const { data } = await fetchMasterDataServicePost(URLS.dmaReporting, dataToSend);
    dispatch(changeLoader(false));
    dispatch({ type: DMA_REPORTING_DATA, payload: data });
    return { success: true };
  } catch (error) {
    if (error?.response?.data?.status === 422) {
      dispatch(toggleActionMessage(true, 'error', error.response.data.message, true));
    }else {
      dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
    }
    dispatch(changeLoader(false));
    dispatch({ type: DMA_REPORTING_DATA, payload: [] });
    return { success: false };
  }
}

export const fetchSixMonthTrafficData = () => async dispatch =>{
  try {
    dispatch(changeLoader(true));
    const { data } = await fetchMasterDataService(URLS.sixMonthstraffic);
    dispatch(changeLoader(false));
    dispatch({ type: SIX_MONTHS_TRAFFIC_DATA, payload: data });
    return { success: true };
  } catch (error) {
    dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
    dispatch(changeLoader(false));
    dispatch({ type: SIX_MONTHS_TRAFFIC_DATA, payload: [] });
    return { success: false };
  }
}
