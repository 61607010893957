import React, { useState, useEffect , useCallback} from 'react'
import strings from 'resources/locales/Translate';
import { getDateInFormat } from 'library/utilities/getDateInFormat';
import cloneDeep from 'lodash/cloneDeep';


export const useControllingReportTab = (props) => {
  const {controllingReport, getControllingReport, exportControllingReportList} = props;
  const [data, setData]= useState({
      page: 0,
      size: 10,
      date:null,
      sort:{
        country:null,
        organization:null
      }
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let dataToSend = {
    toDate: data.date ? getDateInFormat('YYYY-MM-DD', data.date) : null,
    page :data.page,
    size:data.size,
    direction : null,
    columnName : null
    };
    if (data.sort && data.sort.country) {
      dataToSend.columnName="country.name";
      dataToSend.direction = data.sort.country;
    }else if (data.sort && data.sort.organization) {
      dataToSend.columnName="organization";
      dataToSend.direction = data.sort.organization;
    }else if (data.sort && data.sort.activeUntilDate) {
      dataToSend.columnName="activeUntilDate";
      dataToSend.direction = data.sort.activeUntilDate;
    }
      getControllingReport(dataToSend);
  }, [ data.page , data.size, data.date, getControllingReport, data.sort,]);

  const exportList = useCallback(async () => {
    const dataToSend = {
      toDate: data.date ? getDateInFormat('YYYY-MM-DD', data.date) : null,
    };
    setIsLoading(true);
    await exportControllingReportList(dataToSend);
    setIsLoading(false);
  }, [data.date]);

  const handleDateChange = useCallback(date => {
    let newDate1 = null;
     if (date) {
      const tempdate = date ? `${getDateInFormat('YYYY-MM-DD', date)} 00:00:00` : null;
      newDate1 = tempdate;
    }

    setData({ ...data, date });
  }, [data, setData]);

  const handleItemsPerPage = useCallback(size => {
    setData(prevState => ({
      ...prevState,
      size,
    }));
  }, []);

  const handlePageChange = useCallback(page => {
    setData(prevState => ({
      ...prevState,
      page: page,
    }));
  }, []);

  const handleSort = name => {
    setData(prevState => {
      const newData = cloneDeep(prevState);
      Object.keys(newData.sort).forEach(key => (newData.sort[key] = null));
      newData.sort[name] = prevState.sort[name] ? (prevState.sort[name] === 'asc' ? 'desc' : null) : 'asc';

      return newData;
    });
  };

  const getControllingReportCols =()=>[
    {
      title: strings.preName,
      name: 'preName',
      isSort: false,
      render: data => {
        return <>{data.firstName}</>;
      }
    },
    {
      title: strings.surName,
      name: 'surName',
      isSort: false,
      render: data => {
        return <>{data.lastName}</>;
      }
    },
    {
      title: strings.organization,
      name: 'organization',
      isSort: true,
      render: data => {
        return <>{data.organization}</>;
      }
    },
    {
      title: strings.endOfContract,
      name: 'activeUntilDate',
      isSort: true,
      render: data => {
        return <>{data.endOfContract}</>;
      }
    },
    {
      title: strings.city,
      name: 'city',
      isSort: false,
      render: data => {
        return <>{data.city}</>;
      }
    },
    {
      title: strings.countryLand,
      name: 'country',
      isSort: true,
      render: data => {
        return <>{data.countryName}</>;
      }
    },
    {
      title: strings.earnedPoints,
      name: 'earnedPoints',
      isSort: false,
      render: data => {
        return <>{data.pointsEarned}</>;
      }
    },
    {
      title: strings.earnedEuros,
      name: 'earnedEuros',
      isSort: false,
      render: data => {
        return <>{data.pointsEarnedValue}</>;
      }
    },
    {
      title: strings.deductedPoints,
      name: 'deductedPoints',
      isSort: false,
      render: data => {
        return <>{data.deductedPoint}</>;
      }
    },
    {
      title: strings.deductedEuros,
      name: 'deductedEuros',
      isSort: false,
      render: data => {
        return <>{data.deductedPointValue}</>;
      }
    },
    {
      title: strings.sumPoints,
      name: 'sumPoints',
      isSort: false,
      render: data => {
        return <>{data.totalPoint}</>;
      }
    },
    {
      title: strings.sumEuros,
      name: 'sumEuros',
      isSort: false,
      render: data => {
        return <>{data.totalPointValue}</>;
      }
    },

    ]
    const isShowTable = controllingReport && controllingReport.content && controllingReport.content.length > 0;
    const controllingReportCols = getControllingReportCols();

    return {
      data,
      controllingReportCols,
      isShowTable,
      isLoading,
      handleItemsPerPage,
      handlePageChange,
      handleSort,
      handleDateChange,
      exportList
    }
}
