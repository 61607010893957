import React from 'react';

import strings from 'resources/locales/Translate';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import Modal from 'library/common/commonComponents/Modal';

import { useAddPointsPopup } from './AddPointsPopup.hook'


const AddPointsPopup = props => {
  const { id } = props;

  const {
    addPointsForm ,
    addModel,
    isLoading,
    error,
    onSaveClick,
    onCloseClick,
    handleForm,
  } = useAddPointsPopup(props);

  return (
    <Modal
      id={id}
      title={strings.addPoints}
      confirmAction={onSaveClick}
      confirmTitle={strings.save}
      titleOfCancel={strings.cancel}
      loading={isLoading}
      disableBackgroundClose
      onCloseClick={onCloseClick}
    >
      <FormComponent
        model={addModel}
        formName='addPointsForm'
        formValue={addPointsForm.formData}
        onChange={handleForm}
      />
      {error && <div className='error-block'>{error}</div>}
    </Modal>
  );
};

export default AddPointsPopup;
