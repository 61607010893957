import Validators from 'library/utilities/Validators';

export const addPointsModel = [
  {
    label: 'date',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'date',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    dateFormat: 'DD.MM.YYYY',
    activeStartDate: null,
  },
  {
    label: 'editor',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'editor',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-12',
    disabled: true,
  },
  {
    label: 'pointAddition',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'pointAddition',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-12'
  },
  {
    label: 'reason',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'reason',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-12'
  },
  {
    label: 'salesRepresentative',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'salesRepresentative',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'accountId',
    displayValue: [
      { key: 'lastName', separator: ', ' },
      { key: 'firstName', separator: '' },
    ],
    excludeData: [],
  },
];