import Axios from 'axios';
import { saveAs } from 'file-saver';

import { logNetworkError } from 'library/utilities/logError';
import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { deleteKitService, getDataByCriteriaService, fetchProblemListService, fetchMasterDataServicePost } from 'library/api/master-data.service';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { fetchFromStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import * as types from 'library/common/commonConstants/storeConstants';
import { kitListRequestJson } from 'library/api/endpoint-request-json';
import {FETCH_TRANSACTION_LOAN_ORDER} from '../KitDetails/KitDetailsConstants';
import { printDocumentService } from 'library/api/master-data.service';
import { IBASE_NEW_COMPONENTS_LIST, FETCH_NEW_COMPONENTS_LIST } from '../CreateZmccKit/CreateZmccKit.constants';

export const handlingSendData = (data, isLabsLocationSelected) => {
  
  const filter = ['deleted:eq:false', 'and', 'kitName:nn:', 'and', 'isKitManualCreated:eq:false'];
  const {
    filter: { stock, category, status, condition, searchTerm },
  } = data;
  const sort = [];
  const {
    sort: { kitName, serialNumber, dateCreated, systemValue, stockName, currentPosition, country },
  } = data;
  const sendData = { ...data };

  if (stock && stock.length) {
    let stockFilterArray = [];
    if (filter.length) filter.push('and');

    stock.forEach((item, index) => {
      if (index !== 0) stockFilterArray.push('or');
      stockFilterArray.push(`stock.id:eq:${item}`);
    });

    filter.push(stockFilterArray);
  }
  if (category && category.length) {
    let categoryFilter = [];
    if (filter.length) filter.push('and');

    category.forEach((item, index) => {
      if (index !== 0) categoryFilter.push('or');
      categoryFilter.push(`systemMainComponent.id:eq:${item}`);
    });

    filter.push(categoryFilter);
  }

  if (status) {
    const statusFilter = [];
    if (filter.length) filter.push('and');

    status.forEach((item, index) => {
      if (index !== 0) statusFilter.push('or');
      statusFilter.push(`kitStatus.id:eq:${item}`);
    });

    filter.push(statusFilter);
  }
  if (condition) {
    const conditionFilter = [];
    if (filter.length) filter.push('and');

    condition.forEach((item, index) => {
      if (index !== 0) conditionFilter.push('or');
      conditionFilter.push(`kitCondition.id:eq:${item}`);
    });

    filter.push(conditionFilter);
  }
  if (searchTerm) {
    if (filter.length) filter.push('and');
    let searchArray = [];
    searchArray.push(`kitName:cic:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`stock.name:cic:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`serialNumber:cic:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`id:eq:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`description:cic:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`salesComponents:cic:${searchTerm}`);
    if (isLabsLocationSelected) {
      searchArray.push(`or`);
      searchArray.push(`comment:cic:${searchTerm}`);
    }

    filter.push(searchArray);
  }

  if (kitName) {
    sort.push('kitName');
    sort.push(`${kitName}`);
  }
  if (serialNumber) {
    sort.push('serialNumber');
    sort.push(`${serialNumber}`);
  }
  if (dateCreated) {
    sort.push('dateCreated');
    sort.push(`${dateCreated}`);
  }
  if (systemValue) {
    sort.push('systemValue');
    sort.push(`${systemValue}`);
  }
  if (stockName) {
    sort.push('stockInformation.locationName');
    sort.push(`${stockName}`);
  }
  if (currentPosition) {
    sort.push('currentPosition');
    sort.push(`${currentPosition}`);
  }
  if (country) {
    sort.push('country');
    sort.push(`${country}`);
  }

  sendData.filter = filter;
  sendData.sort = sort;
  sendData.graphql = kitListRequestJson;
  return sendData;
};

export const fetchKitsListData =
  (url, actionType, data, isLabsLocationSelected = false) =>
  dispatch => {
    const sendData = handlingSendData(data, isLabsLocationSelected);

    dispatch(changeLoader(true));

    getDataByCriteriaService(url, sendData)
      .then(res => {
        if (res.status === 200) {
          dispatch({
            type: actionType,
            payload: res.data,
          });
          dispatch({
            type: FETCH_TRANSACTION_LOAN_ORDER,
            payload: {},
          });
          dispatch({type: IBASE_NEW_COMPONENTS_LIST, payload: {content : [], ibaseComponents : false}});
          dispatch({ type: FETCH_NEW_COMPONENTS_LIST, payload: {content: []}});
        }
        dispatch(changeLoader(false));
      })
      .catch(err => {
        logNetworkError(err);
        dispatch(changeLoader(false));
        if (err.response && err.response.status === 404) {
          dispatch({
            type: actionType,
            payload: [],
          });
        }
      });
  };

export const deleteKit = id => dispatch => {
  return new Promise(function (resolve, reject) {
    dispatch(changeLoader(true));

    deleteKitService(id)
      .then(res => {
        if (res.status === 200) {
          dispatch(changeLoader(false));
          dispatch(toggleActionMessage(true, 'success', 'kitDeletedSuccessfully'));
          resolve(res);
        }
      })
      .catch(err => {
        logNetworkError(err);
        dispatch(changeLoader(false));
        dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
        reject(err);
      });
  });
};

export const exportKitList =
  ({ stock: stockIds, category: systemMainComponentIds, status: kitStatusIds, condition: kitConditionIds }) =>
  dispatch => {
    const valuesToSend = { stockIds, systemMainComponentIds, kitStatusIds, kitConditionIds };
    const token = fetchFromStorage(identifiers.token);
    return Axios(process.env.REACT_APP_BASE_URL + URLS.exportKitListFilter, {
      method: 'POST',
      responseType: 'blob',
      headers: {
        token: `${token}`,
        'Content-Type': 'application/json',
        'Accept-Language': 'en',
      },
      data: valuesToSend,
    })
      .then(response => {
        try {
          const newFile = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          saveAs(newFile, 'System list');
          dispatch(changeLoader(false));
          dispatch(toggleActionMessage(true, 'success', 'successMessageForExportListKit'));
          return { success: true };
        } catch (e) {
          return { success: false };
        }
      })
      .catch(err => {
        logNetworkError(err);
        dispatch(changeLoader(false));
        dispatch(toggleActionMessage(true, 'error', 'errorMessageForExportListkit'));
        return { success: false };
      });
  };

export const fetchProblemsList = () => dispatch => {
  return new Promise(function (resolve, reject) {
    fetchProblemListService()
      .then(res => {
        if (res.status === 200) {
          dispatch({
            type: types.FETCH_PROBLEM_LIST,
            payload: res.data,
          });
          resolve(res);
        }
      })
      .catch(err => {
        logNetworkError(err);
        dispatch({
          type: types.FETCH_PROBLEM_LIST,
          payload: [],
        });
        reject(err);
      });
  });
};

export const fetchDemoRequestForm  = () => dispatch => {
  dispatch(changeLoader(true));
  printDocumentService(URLS.getDEmoRequestForm)
  .then(res => {
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `DemoRequestForm.pdf`);
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    dispatch(changeLoader(false));
  })
  .catch(err => {
    dispatch(toggleActionMessage(true, 'error', 'fileDownloadError'));
    dispatch(changeLoader(false));
  });
};

export const fetchStockInformationIds = (dataToSend) => async dispatch => {
  try {
    const res = await fetchMasterDataServicePost(URLS.exportSystemClassStock, dataToSend);
    dispatch({
      type: types.FETCH_COUNTRY_LIST,
      payload: res?.data,
    });
    return {success: true};

  } catch (err) {
    dispatch({
      type: types.FETCH_COUNTRY_LIST,
      payload: []
    });
    return {success: false};
  }
};

export const fetchWarehouseBySystemClass = (dataToSend) => async dispatch => {
  try {
    const res = await fetchMasterDataServicePost(URLS.exportStockSystemClass, dataToSend);
    dispatch({
      type: types.FETCH_SYSTEM_CLASS_WITH_STOCKS,
      payload: {systemClasses : res?.data},
    });
    return {success: true};

  } catch (err) {
    dispatch({
      type: types.FETCH_SYSTEM_CLASS_WITH_STOCKS,
      payload: []
    });
    return {success: false};
  }
};