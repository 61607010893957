import { logNetworkError } from 'library/utilities/logError';
import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { getDataByCriteriaService } from 'library/api/master-data.service';

import { deleteStockService } from '../CreateStock/CreateStock.services';
import { ACTIVE_STATUS, INACTIVE_STATUS } from 'library/common/commonConstants/storeConstants';

export const handlingSendData = (data) => {
  const filter = ["deleted:eq:false"];
  const {filter: {countryId, businessId, searchTerm, locationStatus}} = data;
  const sort = [];
  const {sort: {locationName, locationShortName, countryName, countryShortName, businessUnitName, createdOn, email, firstName}} = data;
  const sendData = {...data};

  if (countryId) {
    if (filter.length) filter.push('and');
    filter.push(`country:eq:${countryId}`);
  }
  if (businessId) {
    if (filter.length) filter.push('and');
    filter.push(`businessUnit:eq:${businessId}`);
  }
  if (locationStatus) {
    if (filter.length) filter.push('and');
    filter.push(`locationStatus:eq:${locationStatus==1 ? ACTIVE_STATUS : INACTIVE_STATUS}`);
  }

  if (searchTerm) {
    let searchArray = [];

    if (filter.length) filter.push('and');
    searchArray.push(`locationName:cic:${searchTerm}`);
    searchArray.push('or');
    searchArray.push(`locationShortName:cic:${searchTerm}`);
    searchArray.push('or');
    searchArray.push(`country.name:cic:${searchTerm}`);
    searchArray.push('or');
    searchArray.push(`country.shortName:cic:${searchTerm}`);
    searchArray.push('or');
    searchArray.push(`businessUnit.name:cic:${searchTerm}`);
    filter.push(searchArray);
  }

  if (locationName) {
    sort.push('locationName');
    sort.push(`${locationName}`);
  }
  if (locationShortName) {
    sort.push('locationShortName');
    sort.push(`${locationShortName}`);
  }
  if (countryName) {
    sort.push('country.name');
    sort.push(`${countryName}`);
  }
  if (countryShortName) {
    sort.push('country.shortName');
    sort.push(`${countryShortName}`);
  }
  if (businessUnitName) {
    sort.push('businessUnit.name');
    sort.push(`${businessUnitName}`);
  }
  if (createdOn) {
    sort.push('createdOn');
    sort.push(`${createdOn}`);
  }
  if (firstName) {
    sort.push('firstName');
    sort.push(`${firstName}`);
  }
  if (email) {
    sort.push('email');
    sort.push(`${email}`);
  }

  sendData.filter = filter;
  sendData.sort = sort;
  sendData.graphql =
    'id,locationName,locationShortName,businessUnit[name,id,designation],country[name,shortName,id],userRole[accountId,role[role]],firstName,lastName,email,warehouseActiveAddress[firstName,lastName,email]';

  return sendData;
};

export const fetchStocksListData = (url, actionType, data) => dispatch => {
  const sendData = handlingSendData(data);

  dispatch(changeLoader(true));

  getDataByCriteriaService(url, sendData)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: actionType,
          payload: res.data
        });
      }
      dispatch(changeLoader(false));
    })
    .catch(err => {
      logNetworkError(err);
      dispatch(changeLoader(false));
      console.error(err);
      // if (err.response.status === 404) {
      //   dispatch({
      //     type: actionType,
      //     payload: []
      //   });
      // }
    });
};

export const deleteStock = (id) => dispatch => {
  return new Promise(function (resolve, reject) {
    dispatch(changeLoader(true));

    deleteStockService(id)
      .then(res => {
        if (res.status === 200) {
          dispatch(changeLoader(false));
          dispatch(toggleActionMessage(true, 'success', 'stockDeletedSuccessfully'));
          resolve(res);
        }
      })
      .catch(err => {
        logNetworkError(err);
        dispatch(changeLoader(false));
        if (err.response.status === 409) {
          dispatch(toggleActionMessage(true, 'error', 'canNotDeleteNoEmptyStock'));
        } else {
          dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
        }
        reject(err);
      })
  })
};
