import { useState, useCallback, useEffect } from 'react';
import $ from 'jquery';

import strings from 'resources/locales/Translate';
import { validateForm } from 'library/utilities/ValidateForm.util';
import { getDateInFormat } from 'library/utilities/getDateInFormat';
import { addPointsModel } from './AddPointsPopup.constants';

export const useAddPointsPopup = props => {
  const { id, user, userList, state, saveAddPoint } = props;
  const [addPointsForm, setAddPointsForm] = useState({
    formData: {
      date: new Date(),
      editor: `${user.firstName} ${user.lastName}`,
    }
  });
  const [addModel, setAddModal] = useState(addPointsModel);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const newModel1 = [...addModel];

    if (userList && userList.length) {
        newModel1[4].options = userList;
    }

    setAddModal(newModel1);
  }, [user, userList]);

  const onSaveClick = useCallback(async () => {
    setError('');
    const model = [...addModel]
    const validForm = validateForm({
      form: addPointsForm,
      model,
    });

    if (!validForm.isFormValid) {
      return setAddPointsForm(validForm);
    } 
  
    setIsLoading(true);

    const { date, pointAddition, reason, salesRepresentative } = validForm.formData;
    const dataToSend = {
      stockId: state.filter.stock,
      date: getDateInFormat('YYYY-MM-DD', date),
      editorId: user.accountId,
      salesRepresentativeId: salesRepresentative,
      value: pointAddition,
      reason,
    };

    const { success } = await saveAddPoint(dataToSend, state);

    setIsLoading(false);

    if (!success) {
      setError(strings.somethingWentWrongMessage);
    } else {
        onCloseClick();
    }
  }, [addPointsForm, saveAddPoint]);

  const onCloseClick = useCallback(() => {
    setAddPointsForm({
      formData: {
        date: new Date(),
        editor: `${user.firstName} ${user.lastName}`,
      }
    });
    setError('');
    closeAddPointsPopup();
  }, []);

  const handleForm = useCallback(({ values, field }) => {
    if (field === 'pointAddition') {
      const regex = /^(\d+(\.\d{0,2})?|\.?\d{1,2})?$/; // contains a positive integer or floating point number up to two characters after the decimal point. Example: 1, 1.1, 1.11
      const isValid = regex.test(values.pointAddition);

      if (isValid) {
        return setAddPointsForm(prevState => ({
          ...prevState,
          formData: {
            ...prevState.formData,
            ...values,
          },
        }));
      }
    } else {
      return setAddPointsForm(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          ...values,
        },
      }));
    }
  }, []);

  const closeAddPointsPopup = useCallback(() => {
    $(`#${id}`).modal('hide');
  }, []);

  return {
    addPointsForm,
    addModel,
    isLoading,
    error,
    onSaveClick,
    onCloseClick,
    handleForm,
  };
}
