import React, { createRef } from 'react';
import cn from 'classnames';
import Icon from 'library/common/commonComponents/Icon/Icon';

import strings from 'resources/locales/Translate';
import Dropdown from 'library/common/commonComponents/Dropdown/Dropdown';

const Filter = ({ businessUnit, countries, handleFilterChange, isShowFilter, resetFilter, selected, isUserPartner, admin, locationStatus}) => {
  const countryRef = createRef();
  const businessRef = createRef();
  const locationStatusRef = createRef();

  const currentResetFilter = key => () => {
    if (!key) {
      countryRef.current.resetSelectedValue();
      businessRef.current.resetSelectedValue();
      locationStatusRef.current.resetSelectedValue();
    } else if (key === 'countryId') {
      countryRef.current.resetSelectedValue();
    } else if(key === 'locationStatus'){
      locationStatusRef.current.resetSelectedValue();
    } else if(key === 'businessId'){
      businessRef.current.resetSelectedValue();
    }
    resetFilter(key);
  };

  const getPlaceholder = (selectedValues, displayValue, multiple) => {
    let displayPlaceholder = '';
    if (!multiple && selectedValues) {
      displayPlaceholder = displayValue
        .map(display => {
          if (display.name) {
            return selectedValues[display.key] + display.separator + selectedValues[display.name];
          }
          return selectedValues[display.key] + display.separator;
        })
        .join('');
    }
    return displayPlaceholder;
  };

  const renderFilters = () => {
    const newFilters = [];
    let filters = {
        businessId: { displayValue: [{ key: 'name', separator: '' }] },
        countryId: { displayValue: [{ key: 'name', separator: '' }] },
        locationStatus: { displayValue: [{ key: 'name', separator: '' }] },
      };
    if (!isUserPartner) {
      Object.keys(filters).forEach(key => {
        if (selected[key] && (typeof selected[key] !== 'object' || selected[key].length)) {
          newFilters.push(
            <div className='filter-chips-container'>
              {getPlaceholder(selected[`${key}FullValue`], filters[key].displayValue, filters[key].multiple)}
              <span onClick={currentResetFilter(key)}>&times;</span>
            </div>,
          );
        }
      });
    } else if (selected.countryIdFullValue !== null) {
      newFilters.push(
        <div className='filter-chips-container'>
          {getPlaceholder(selected[`countryIdFullValue`], filters['countryId'].displayValue, filters['countryId'].multiple)}
          <span onClick={currentResetFilter('countryId')}>&times;</span>
        </div>,
      )
    }
    return newFilters;
  };

  const filters = renderFilters();
  return (
    <>
      <div className={cn('filter-wrapper', { 'd-block': isShowFilter, 'd-none': !isShowFilter })}>
        <div className='container-fluid'>
          <div className='row'>
            <Dropdown
              ref={countryRef}
              data={countries}
              hasSection={false}
              multiSelect={false}
              filter={true}
              clearOption={true}
              idKey='id'
              displayValue={[{ key: 'name', separator: '' }]}
              isRequired={false}
              placeholder={`${strings.pleaseSelect}...`}
              label={strings.countryLand}
              onChangeValue={handleFilterChange('countryId')}
              mainContainerStyle= {admin ? 'col-lg-4':'col-lg-6'}
              selectAllPlaceholder='allCountries'
            />
          {
            !isUserPartner && <Dropdown
              ref={businessRef}
              data={businessUnit}
              hasSection={false}
              multiSelect={false}
              filter={false}
              clearOption={true}
              idKey='id'
              displayValue={[{ key: 'name', separator: '' }]}
              isRequired={false}
              placeholder={`${strings.pleaseSelect}...`}
              label={strings.businessUnit}
              onChangeValue={handleFilterChange('businessId')}
              mainContainerStyle= {admin ? 'col-lg-4 mt-3 mt-lg-0':'col-lg-6 mt-3 mt-lg-0'}
              selectAllPlaceholder='allBusinessUnits'
            />
          }

          {
            admin && <Dropdown
              ref={locationStatusRef}
              data={locationStatus}
              hasSection={false}
               multiSelect={false}
              filter={false}
              clearOption={true}
              idKey='id'
              displayValue={[{ key: 'name', separator: '' }]}
              isRequired={false}
              placeholder={`${strings.pleaseSelect}...`}
              label={strings.locationStatus}
              onChangeValue={handleFilterChange('locationStatus')}
              mainContainerStyle= {admin ? 'col-lg-4 mt-3 mt-lg-0':'col-lg-6 mt-3 mt-lg-0'}
              selectAllPlaceholder=''
            />
          }
          </div>
        </div>
      </div>
      {filters && !!filters.length && (
        <div className='container-fluid mb-4 d-flex flex-direction-row align-items-center'>
          <div className='mr-2'>
            <strong>Filters:</strong>
          </div>
          {filters}
          {
            !isUserPartner && (
              <div className='filter-chips-delete-all' onClick={currentResetFilter()}>
                <Icon name='delete' width={24} height={24} fill='#0088d0' /> {strings.deleteAllFilters}
              </div>
            )
          }
        </div>
      )}
    </>
  );
};
export default Filter;
