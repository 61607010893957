import { connect } from 'react-redux';

import { saveAddPoint } from '../../LLManagement.actions';
import AddPointsPopup from './AddPointsPopup.component';

const mapStateToProps = ({ authReducer, languageReducer, llManagementReducer }) => ({
  user: authReducer.user,
  language: languageReducer,
  userList: llManagementReducer.userList,
});

export default connect(mapStateToProps, { saveAddPoint })(AddPointsPopup);
